<template>
    <div class="af">
        <el-container>
            <el-header height="100%">
              <vheadere class="hidden-sm-and-down"></vheadere>
              <ivheaderae class="hidden-md-and-up"></ivheaderae>
              <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>MORE</p>
             </div>
           </div>
            </el-header>
            <el-main style="padding-bottom: 1rem;" class="w afad">
                <div class="afad-mie">
                    <div class="ec-top-foot">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item > <router-link to="/EN">Home</router-link></el-breadcrumb-item>
                            <el-breadcrumb-item>More</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>
                <div class="mie">
                    <img src="../../assets/images/en/A/learnmore.png" alt="">
                </div>
                <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="24"  class="ems w">
                        <el-col :xs="24" :sm="24" :md="12" class="card-ca" v-for="(item, index) in cityf" @click="goto" :key="index">
                            <div class="card">
                                <div class="card-af">
                                    <el-col :xs="24" :sm="24" :md="24" class="img-ic" >
                                        <div class="card-icon">
                                            <div class="icon-cn-txt"> <a :href="item.URL" >{{item.TITLE}}</a></div>
                                            <div class="icon-en-txt"><a :href="item.URL" >{{item.URL}}</a></div>
                                        </div>
                                        <div class="img-i">
                                            <a :href="item.URL"><img :src="item.IMAGE" alt=""></a>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="24">
                                        <div class="card-txt">
                                        {{item.SHORTCONTENT}}
                                        </div>
                                    </el-col>
                                </div>
                            </div>
                        </el-col>
                    </el-col>
                </el-row>
            </el-main>
            <el-footer>
              <vfootere class="hidden-sm-and-down"></vfootere>
              <ivfootere class="hidden-md-and-up"></ivfootere>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import { szcityf } from '../../api-en'
export default {
  data () {
    return {
      cityf: {}
    }
  },
  mounted () {
    szcityf().then(res => {
      console.log(res.data)
      this.cityf = res.data
      console.log(this.cityf)
      console.log(this.cityf.URL)
    })
  }
}
</script>

<style scoped>
.top-img {
    position: relative;
}
.top-img-top {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #FFFFFF;
    text-align: left;
}
.top-img-top p {
    font-size: 0.6rem;
    font-weight: 600;
}
.top-img-top span {
    font-size: 0.45rem;
    font-weight: 300;
}
.top-img .top-img-top:after {
    content: '';
    position: absolute;
    left: 0;
    top: -16px;
    height: 1px;
    width: 40%;
    background: #FFF;
}
.af .mie {
    width: 100%;
    height: 2rem;
}
.af .mie img {
    float: left;
    padding-top: 0.22rem;
}
.afad {
    position: relative;
}
.afad .afad-mie .ec-top-foot {
    position: absolute;
    top: -49px;
    left: 25px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.797rem;
    align-items: center;
}
.afad .afad-mie .ec-top-foot .el-breadcrumb {
    line-height: 0.79rem;
    padding-left: 10px;
}
.el-main {
    margin-top: 0;
    overflow: initial;
}
.item {
    position: relative;
    margin-right: 8%;
    margin-bottom: 10%;
}
.item-r {
    position: relative;
}
.el-header {
    padding: 0;
}
.item-t {
    position: absolute;
    top: 26%;
    left: 7%;
    font-size: 0.35rem;
    color: #00B6CD;
}
.item-txt {
    position: absolute;
    top: 36%;
    left: 7%;
    font-size: 0.25rem;
    color: #0FB7D1;
}
.item-i {
    padding-top: 4px;
    position: absolute;
    top: 26%;
    right: 7%;
    width: 10%;
}
.item-p {
    position: absolute;
    bottom: 7%;
    left: 6%;
    padding-right: 5%;
    text-align: left;
    font-size: 0.25rem;
}
.item-r-p {
    position: absolute;
    bottom: 14%;
    left: 6%;
    padding-right: 5%;
    text-align: left;
    font-size: 0.25rem;
}
.item-r-t {
    position: absolute;
    top: 26%;
    left: 7%;
    font-size: 0.35rem;
    color:rgba(165,205,57,1);
}
.item-r-txt {
    position: absolute;
    top: 36%;
    left: 7%;
    font-size: 0.25rem;
    color:rgba(165,205,57,1);
}
.item-r-i {
    padding-top: 4px;
    position: absolute;
    top: 26%;
    right: 7%;
    width: 10%;
}
.ems {
    padding: 0 15px;
    margin-bottom: 3rem;
}
.ems .card {
    border: 1px solid #d8d8d8;
    margin-top: 0.4rem;
    position: relative;
    height: 7rem;
    float: left;
    margin: 0.4rem 1rem 0 0;
    margin-right: 0.4rem;
}
.ems .card:nth-child(2n) {
    margin-right: 0;
}
.card-af .img-ic {
    display: flex;
    justify-content: space-between;
}
.img-ic .img-i{
    position: relative;
    flex: 1;
}
.img-ic .img-i img {
    position: absolute;
    right: 0.3rem;
    top: 58%;
    width: 20%;
}
.img-ic .card-icon {
    flex: 20%;
}
.card:nth-child(2n) {
    margin-right: 0;
}
.card:before {
    content: ' ';
    border-top: 0.5rem solid #d8d8d8;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    position: absolute;
    top: -1px;
    margin-left: -0.4rem;
    left: 50%;
}
.ems .card:after {
    content: ' ';
    border-top: 0.5rem solid #fff;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    position: absolute;
    top: -2px;
    margin-left: -0.4rem;
    left: 50%;
}
.ems .card .card-af:after {
    content: '';
    position: absolute;
    right: 0;
    top: -1px;
    height: 3px;
    width: 30%;
    background: rgba(15,183,209,1);
}
.ems .card-ca:nth-child(2n) .card .card-af:after {
    background: rgba(165,205,57,1);
}
.card .card-icon {
    margin-top: 1.2rem;
    margin-left: 0.5rem;
    text-align: left;
    color: rgba(15,183,209,1);
}
.card .card-icon .icon-cn-txt {
    font-size: 0.4rem;
    white-space:nowrap;
}
.card .card-icon .icon-en-txt {
    font-size: 0.25rem;
}
.card-ca .card .card-icon .icon-cn-txt a {
    color: rgba(15,183,209,1);
}
.card-ca:nth-child(2) .card .card-icon .icon-cn-txt a {
    color: rgba(165,205,57,1);
}
.card-ca .card .card-icon .icon-en-txt a {
    color: rgba(15,183,209,1);
}
.card-ca:nth-child(2) .card .card-icon .icon-en-txt a {
    color: rgba(165,205,57,1);
}
.ems .card .card-txt {
    /* margin-top: 1.2rem; */
    font-size: 0.3rem;
    color: #6A6969;
    line-height: 0.45rem;
    text-align: left;
    font-size: 0.24rem;
    margin: 1.3rem 0.4rem 0;
}
@media screen and (max-width:1100px) {
    .afad .ec-top-foot {
        display: none;
    }
    .mie img {
        width: 60%;
    }
}
@media screen and (max-width:768px){
    .el-main {
        padding-top: 1rem;
    }
    .ems {
      margin-bottom: 2.5rem;
    }
    .ems .card {
        margin-right: 0;
    }
     .af .mie img {
        width: 60%;
    }
    .img-ic .img-i img {
        position: absolute;
        right: 0.3rem;
        top: 58%;
        width: 33%;
    }
    .card-ca:nth-child(2) .card .card-af .img-ic .img-i img {
        width: 26%;
    }
}
</style>
<style>
.el-breadcrumb .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
}
.el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
}
.el-breadcrumb__inner a:hover {
    color: #fff600;
}
</style>
